*{
  margin: 0;
}

.Portfolio {
  max-width: 100%;
  background-color: #343a40 !important;
}
.Portfolio_Nav{
  text-align: center;
  color: white;
  font-size: 100px;
  opacity: 0.1;
  margin-bottom: -70px;

}
.Portfolio_title{
  text-align: center;
  color: white;
  font-weight: bold;
  font-size: 30px; 

  

}
.Portfolio_textWrapper{
  padding: 50px;
}

.portfolioComponent2{
  align-items: center;
  max-width: 350px;
  max-height: 700px;
  margin-left: 50px;
}
.portfolioComponent2_imageWrapper {
  width: 350px;
  height: 600px;
  border-radius: 10px;
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 10px;
  margin-bottom: -300px;


}
.portfolioComponent2_imageWrapper:hover {
opacity: 0.2;
transition: .5s;
}

.Portfolio_componentWrapper {
  width: 80%;
  display: flex;
  justify-content: space-between;
  margin: auto;
  padding: 100px;
}
.PortfolioComponent_main {
  width: 300px;
  height: 250px;
  margin-bottom: 20px;
}
.PortfolioComponent {
  border-radius: 10px;
  width: 350px;
  height: 250px;
  margin-right: 40px;
  margin-bottom: -150px;
  background-size: cover;
  background-repeat: no-repeat;

}
.PortfolioComponent:hover {
  opacity: 0.2;
  transition: 0.5s;
}
.PortfolioComponent_paragraph {
  padding-left: 100px;
  font-size: 20px;
  font-weight: bold;
  color:rgba(22, 224, 200, 0.744);
  /* opacity: 1; */
}

