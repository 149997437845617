
.about{
   max-width: 100%;
    height: auto;
    background-color: #212529!important;
    color:white;
}
.about_header{
    text-align: center;
padding: 50px;
}
.about_header_bold{
    font-size: 150px;
    text-align: center;
    margin-bottom: -130px;
    opacity: 0.1;
}
.about_header{
    font-size: 30px;
}
.about_information{
    width: 80%;
    margin: auto;
    display: flex;
    padding: 30px;
}
.about_information_paragraph{
    width: 70%;
    font-size: 20px;
}
.about_information_paragrap h3{
    color: rgba(0, 0, 255, 0.41);
}
.about_information_paragraph p{
    color: hsla(0,0%,100%,.5)!important;

}
.about_information_email ul li{
    list-style: none;
    color: white;
    font-size: 20px;

}
.about_information_email button{
    background-color: rgb(53, 169, 130);
    width: 180px;
    height: 50px;
    padding: 10px;
    border-radius: 20px;
    font-size: 20px;
    margin: 30px;
    border: 1px;
}
.about_projectNo{
    display: flex;
    width: 80%;
margin: auto;
justify-content: space-between;
padding: 50px 0;
}
.about_projectNo h2{
    color: hsla(0,0%,100%,.5)!important;
    font-size: 50px;

}
.about_information_email hr {
    margin:15px;
    width: 300px;
opacity: 0.2;
}
.about_information_email li {
    margin-left: 20px;
    color: hsla(0,0%,100%,.5)!important;

}