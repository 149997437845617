.service{
    width: 100%; 
    height: auto;
    background-color: #343a40!important;
    color: hsla(0,0%,100%,.5)!important;
}
.service_header{

}
.service_header h2{
    text-align: center;
    color: white;
    font-size: 40px;
}

.service_header_bold{
    font-size: 150px;
    text-align: center;
    opacity: 0.1;
    color: white;
    margin-bottom: -100px;
    z-index: -1;
}
.service_componentWrapper{
    width: 70%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    padding: 50px;
}
.ServiceComponent{
    display: flex;
align-items: center;
}
.ServiceComponent_icon{
    width: 70px;
    height: 70px;
    background-color: rgba(0, 0, 0, 0.728);
    border-radius: 5px;
    margin: 20px;
    margin-right: 30px;
}
.ServiceComponent_paragraph_header{
    font-size: 20px;
    font-weight: bold;
    color: white;
}